const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  USER_EDIT: 'user/USER_EDIT',
  USER_DELETE: 'user/USER_DELETE',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  SET_PAGE: 'user/SET_PAGE',
}

export default actions
