const PATH = {
  HOME: '/',
  WELCOME: '/welcome',
  STORED_WELCOME: '/home',
  LIVE: '/live',
  VOD: '/vod',
  ABOUT: '/about',
  SURVEY: '/survey',
  SURVEY_COMPLETE: '/survey-complete',
  PROGRAMS: '/programs',
  SPEAKER: '/speaker',
  GUIDE: '/guide',
  GRADE_INFORMATION: '/grade-information',
  MYPAGE: '/mypage',
  SPONSOR: '/sponsor',
  HISTORY: '/history',
  HISTORY_1: '/history/1',
  HISTORY_2: '/history/2',
  HISTORY_3: '/history/3',
  HISTORY_4: '/history/4',
  LOCATION: '/location',
  TABLET: '/open/tablet',

  AUTH: {
    LOGIN: '/auth/signin',
    LOGOUT: '/auth/signout',
    REGISTER: '/auth/signup',
    REGISTER_SHORT: '/r',
    REGISTER_INFO: '/auth/information',
    REGISTER_CONFIRM: '/auth/confirm-registration',
    FORGOT_PASSWORD: '/auth/forgot-password',
    REGISTER_COMPLETE: '/auth/welcome',
    REGISTER_FAILED: '/auth/register-error',
  },

  SYSTEM_404: '/auth/404',
}

export default PATH
