/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import { TEXT } from 'constant'
import PATH from 'path.config'
import MainLayout from './Main'
import AuthLayout from './Auth'
import AdminLayout from './Admin'
import PublicLayout from './Public'
import OpenLayout from './Open'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  admin: AdminLayout,
  main: MainLayout,
  open: OpenLayout,
}

NProgress.configure({ showSpinner: false })

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  // const [transparentNavigation, setTransparentNavigation] = useState(false)

  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    // if (
    //   pathname === PATH.PUBLIC.WELCOME ||
    //   // pathname === PATH.PUBLIC.STREAMING ||
    //   pathname === PATH.PUBLIC.HOME ||
    //   pathname === PATH.PUBLIC.QRCODE
    // ) {
    //   return 'public'
    // }

    // if (pathname === PATH.PUBLIC.OPEN_STREAMING || pathname === PATH.PUBLIC.CHINA_STREAMING) {
    //   return 'publicCustom'
    // }

    if (/^\/open(?=\/|$)/i.test(pathname)) {
      return 'open'
    }
    if (
      /^\/auth(?=\/|$)/i.test(pathname) ||
      pathname === PATH.AUTH.REGISTER_COMPLETE ||
      pathname === PATH.AUTH.REGISTER_SHORT
    ) {
      return 'main'
    }
    // if (pathname === PATH.PUBLIC.INTRODUCE || pathname === PATH.PUBLIC.PROGRAMS) {
    //   return 'authFullWidth'
    // }
    if (/^\/admin(?=\/|$)/i.test(pathname)) {
      return 'admin'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'
  const isPublicLayout = getLayout() === 'public'
  const isOpenLayout = getLayout() === 'open'

  const BootstrappedLayout = () => {
    let transparentNavigation = false
    if (pathname === PATH.WELCOME || pathname === PATH.HOME) {
      transparentNavigation = true
    }

    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    if (isPublicLayout) {
      return <Container>{children}</Container>
    }

    if (isOpenLayout) {
      return <Container>{children}</Container>
    }
    // if (!isUserAdmin && isAdminLayout) {
    //   return <Redirect to={PATH.HOME} />
    // }
    // // redirect to login page if current is not login page and user not authorized
    // if (!isAuthLayout && !isUserAuthorized) {
    //   return <Redirect to={PATH.AUTH.LOGIN} />
    // }

    // in other case render previously set layout
    return <Container transparentNavigation={transparentNavigation}>{children}</Container>
  }

  return (
    <>
      <Helmet titleTemplate={`${TEXT.TITLE.TITLE} | %s`} title="" />
      {BootstrappedLayout()}
    </>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
