import React from 'react'
import { useSelector } from 'react-redux'
import { TEXT } from 'constant'
import { renderDraft } from 'components/Helper'
import PageLoading from 'components/PageLoading/small'
import style from './style.module.scss'

const Footer = () => {
  const settings = useSelector((state) => state.Project.settings)

  const renderFooterLogo = () => {
    if (settings.largeFooterLogoImageUrl) {
      return <img src={settings.largeFooterLogoImageUrl} alt="logo" className={style.logo} />
    }
    return null
  }
  const renderFooter = () => {
    if (settings) {
      return renderDraft(settings.pageFooter)
    }
    return (
      <>
        <p className={`${style.title} mb-3`}>{TEXT.TITLE.TITLE}</p>
        <p className={`${style.copyright} mb-5`}>{TEXT.COPYRIGHT}</p>
        <p className={`${style.additionalInfo}`}>
          {TEXT.EMAIL} <a href={`tel:${TEXT.TELEPHONE}`}>{TEXT.TELEPHONE}</a>
        </p>
      </>
    )
  }

  if (!settings) {
    return <PageLoading rows={3} />
  }

  return (
    <div className={style.footer}>
      <div className={`${style.footerInner} `}>
        <div className={`${style.textContainer} `}>{renderFooter()}</div>
        <div className={`${style.logoContainer} `}>{renderFooterLogo()}</div>
      </div>
    </div>
  )
}

export default Footer
