import React from 'react'

import { useSelector, connect } from 'react-redux'
import { Link } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
import { FormattedMessage } from 'react-intl'
// import { Button } frsom 'antd'
// import { Badge } from 'antd'
import PATH from 'path.config'

import { TEXT } from 'constant'
import { renderDraft } from 'components/Helper'
import style from './style.module.scss'

const mapStateToProps = ({ user, sessions }) => ({ user, sessions })

const TopBar = ({ dispatch, user }) => {
  const settings = useSelector((state) => state.Project.settings)
  // console.log(sessions)
  // eslint-disable-next-line no-unused-vars

  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const renderLogo = () => {
    if (settings) {
      if (settings.largeLogoImageUrl) {
        return <img src={settings.largeLogoImageUrl} alt="logo" className={style.logoImage} />
      }
      return renderDraft(settings.pageMobileHeader)
    }
    return TEXT.TITLE.TITLE
  }

  return (
    <div className={`${style.topbar}`}>
      <div className="mr-4 ml-5 d-block d-md-none">
        <div className={style.logo}>
          <Link to={PATH.HOME}>{renderLogo()}</Link>
        </div>
      </div>

      {/* <div className="mr-4 d-none d-md-block">
        <span className="text-primary">{user.name}</span> 님 환영합니다.
      </div> */}
      <div className="mb-0 ms-auto ml-md-0 font-size-16">
        {settings && settings.signInOpened === true && !user.authorized ? (
          <Link to={PATH.AUTH.LOGIN} className={`${style.highlight} d-md-none d-inline`}>
            <FormattedMessage id="topBar.profileMenu.signIn" />
          </Link>
        ) : (
          <>
            <span className={`${style.highlight} d-md-inline d-none`}> | </span>
            <a href="#" onClick={logout} className={`${style.highlight} d-md-none d-inline`}>
              <FormattedMessage id="topBar.profileMenu.logout" />
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
