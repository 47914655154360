import React from 'react'
import { Skeleton } from 'antd'
import style from './style.module.scss'

const PageLoading = ({ message, rows }) => {
  return (
    <div className={`${style.container} ${style.smallSkeleton}`}>
      <Skeleton active paragraph={{ rows: rows }} />
      {message ? <div className={style.message}>{message}</div> : null}
    </div>
  )
}

export default PageLoading
