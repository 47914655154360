// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { TEXT } from 'constant'
import classNames from 'classnames'
import TopBar from 'components/UI/layout/OpenTopBar'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const PublicLayout = ({
  children,
  isTopbarFixed,
  isGrayTopbar,
  // isContentMaxWidth,
  // isAppMaxWidth,
  // isGrayBackground,
  // isSquaredBorders,
  // isCardShadow,
  // isBorderless,
}) => {
  return (
    <div>
      <Layout>
        <Layout.Header
          className={classNames('cui__layout__header', {
            cui__layout__fixedHeader: isTopbarFixed,
            cui__layout__headerGray: isGrayTopbar,
          })}
        >
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className={`${style.base}`}>{children}</div>
        </Layout.Content>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(PublicLayout))
