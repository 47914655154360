export const SETTINGS = {
  HOST: 'localhost',
  VOD_BASE_URL: process.env.REACT_APP_VOD_BASE_URL,
}

export const USER = {
  TYPE: {
    ADMIN: 'admin',
    LISTENER: 'listener',
    SPEAKER: 'speaker',
    SENIOR: 'senior',
  },
}

export const SESSION_PART = {
  intro: '시작',
  part1: '1부',
  part2: '2부',
  closing: '종료',
  break: '쉬는시간',
}
export const SESSION_ORDER = ['intro', 'part1', 'break', 'part2', 'closing']

export const USER_FIELD_TYPE = {
  TEXT: 'text',
  SELECT: 'select',
  CHECK: 'check',
  PHONE: 'phone_number',
  NUMBER: 'number',
  DATE: 'date',
  BOOL: 'bool',
}

export const TEXT = {
  COPYRIGHT: process.env.REACT_APP_COPYRIGHT,
  TELEPHONE: process.env.REACT_APP_TELEPHONE,
  EMAIL: process.env.REACT_APP_EMAIL,
  // COPYRIGHT2: 'CUBE KOREA SYSTEM Network Broadcasting',s
  TITLE: {
    TITLE: process.env.REACT_APP_TITLE,
    DESCRIPTION: process.env.REACT_APP_DESCRIPTION,
    CURRENT: process.env.REACT_APP_CURRENT,
    CURRENT_SHORT: process.env.REACT_APP_CURRENT_SHORT,
  },
}
